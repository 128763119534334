<template>
  <div class="order-status">
    <div class="status-content" v-if="isShowData">
      <img class="icon" v-if="isSuccess" src="../../assets/pay_success.png" alt />
      <img class="icon" v-if="!isSuccess" src="../../assets/pay_failed.png" alt />
      <div class="order-info">
        <div class="prduct-name" v-if="data">商品名称: {{data.productName}}</div>
        <div class="order-no">
          订单:
          <span>{{orderNo}}</span>
        </div>
        <div class="order-msg" :class="{'failed' : !isSuccess}">{{msg}}</div>
      </div>
      <div class="order-btn">
        <div class="btn-a btn-green" v-if="isSuccess" @click="goHome">返回首页</div>
        <div
          class="btn-a btn-red"
          v-if="!isSuccess"
          :class="{'ma-right': !isSuccess}"
          @click="refreshOrder"
        >刷新订单</div>
        <div class="btn-a" v-if="!isSuccess" @click="goShop">重新购买</div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderDetail } from "@/api/orders";
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      msg: "支付成功",
      isSuccess: false,
      isShowData: false,
      data: null,
      orderNo: null
    };
  },
  created() {
    const { orderNo, wx } = this.$route.query;
    if (!orderNo) {
      this.$router.push({ path: "/mall/list" });
      return;
    }
    // 判断是不是微信支付,微信支付需要另外处理
    if (wx) {
      this.orderNo = orderNo;
      Dialog.confirm({
        title: "支付提示",
        message: "确认微信支付已完成?",
        confirmButtonText: "已完成支付",
        cancelButtonText: "取消"
      })
        .then(() => {
          this.getData();
        })
        .catch(() => {
          // this.goHome();
          this.getData();
        });
    } else {
      this.orderNo = orderNo;
      this.getData();
    }
  },
  methods: {
    getData(isToast) {
      orderDetail(this.orderNo).then(res => {
        this.isShowData = true;
        if (res.code !== 0) {
          this.$router.push({ path: "/mall/list" });
          return;
        }
        // console.log("res = ", res);
        this.data = res.data;
        if (this.data.status <= 1) {
          this.isSuccess = false;
          this.msg = "支付失败,请刷新订单或重新购买";
        } else {
          this.isSuccess = true;
          this.msg = "支付成功";
        }
        if (isToast) {
          Toast("刷新成功");
        }
      });
    },
    goHome() {
      this.$router.goMallHome();
    },
    refreshOrder() {
      this.getData(true);
    },
    goShop() {
      this.$router.push({
        path: "/mall/detail/" + this.data.productId
      });
    }
  }
};
</script>

<style lang="scss">
.order-status {
  height: 100%;
  .status-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .icon {
      margin-top: 100px;
    }
    .order-info {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .order-no {
        text-align: center;
        span {
          color: #eb815f;
        }
      }
      .prduct-name {
        text-align: center;
        margin-bottom: 10px;
      }
      .order-msg {
        text-align: center;
        margin-top: 10px;
      }
      .order-msg.failed {
        color: red;
      }
    }
    .order-btn {
      display: flex;
      align-items: center;
      margin-top: 30px;
      .btn-a {
        // margin-right: 20px;
        width: 150px;
        line-height: 45px;
        border: 1px solid #1989fa;
        color: #1989fa;
        border-radius: 10px;
        text-align: center;
        font-size: 18px;
      }
      .ma-right {
        margin-right: 20px;
      }
      .btn-green {
        border: 1px solid #67c276;
        color: #67c276;
      }
      .btn-red {
        border: 1px solid red;
        color: red;
      }
    }
  }
}
</style>