<template>
  <div class="address-edit">
    <van-nav-bar :title="titleText" left-arrow fixed @click-left="$router.goBack(true)" />
    <div class="content" :class="{'maginTop': true}">
      <van-address-edit
        ref="addr"
        :area-list="areaList"
        show-postal
        show-delete
        :address-info="addressInfo"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        :is-saving="btnIsClick"
        @save="onSave"
        @delete="onDelete"
      />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { addAddr, detailAddr, updateAddr, deleteAddr } from "../../api/address";
import areaList from "../../common/area";
export default {
  data() {
    return {
      titleText: "添加地址",
      addressInfo: {
        id: null,
        userId: null,
        name: "",
        tel: "",
        province: "",
        city: "",
        county: "",
        addressDetail: "",
        areaCode: "", // 地区编码，通过省市区选择获取（必填）
        postalCode: "", // 邮政编码
        isDefault: false
      },
      mode: 1, // 1 添加模式 0 修改模式
      areaList,
      searchResult: [],
      btnIsClick: false // 防止重复点击
    };
  },
  created() {
    const { id } = this.$route.query;
    if (id) {
      this.mode = 0;
      this.titleText = "修改地址";
      this.getDetail(id);
    } else {
      this.mode = 1;
      this.titleText = "添加地址";
    }
  },
  methods: {
    onSave(content) {
      // console.log("content =", content);
      // Toast("save");
      if (this.btnIsClick) {
        Toast("无需重复点击");
        return;
      }
      this.btnIsClick = true;
      if (this.mode) this.addData(content);
      else this.updateData(content);
    },
    async onDelete(content) {
      // console.log("content =", content);
      const { code, msg } = await deleteAddr(content.id);
      if (code !== 0) {
        Toast(msg);
        return;
      }
      Toast("删除成功");
      this.$router.goBack(true);
    },
    async addData(content) {
      // console.log("content =", content);
      const res = await addAddr({
        consignee: content.name,
        phone: content.tel,
        province: content.province,
        city: content.city,
        area: content.county,
        areaCode: content.areaCode,
        postalCode: content.postalCode,
        address: content.addressDetail
      });
      console.log("res =", res);
      if (res.code !== 0) {
        Toast("添加失败,请重试");
        this.btnIsClick = false;
        return;
      }
      this.btnIsClick = false;
      Toast("添加成功");
      this.$router.goBack(true);
    },
    async updateData(content) {
      // console.log("content =", content);
      const res = await updateAddr({
        id: content.id,
        userId: content.userId,
        consignee: content.name,
        phone: content.tel,
        province: content.province,
        city: content.city,
        area: content.county,
        areaCode: content.areaCode,
        postalCode: content.postalCode,
        address: content.addressDetail
      });
      console.log("res =", res);
      if (res.code !== 0) {
        Toast("修改失败,请重试");
        this.btnIsClick = false;
        return;
      }
      this.btnIsClick = false;
      Toast("修改成功");
      this.$router.goBack(true);
    },
    async getDetail(id) {
      const res = await detailAddr(id);
      if (res.code !== 0) {
        Toast("地址信息不存在");
        this.$router.goBack(true);
        return;
      }
      const data = res.data;
      this.addressInfo = {
        id: data.id,
        userId: data.userId,
        name: data.consignee,
        tel: data.phone,
        province: data.province,
        city: data.city,
        county: data.area,
        areaCode: data.areaCode,
        postalCode: data.postalCode,
        addressDetail: data.address
      };
    }
  }
};
</script>

<style>
.van-button--danger {
  background: #eb815f;
  border: 1px solid #eb815f;
}
.content {
  /* padding-top: 46px; */
}
</style>